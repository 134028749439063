/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import axiosClient from '../../../utils/axios';
import { resolve } from '../../../api/endPoints';
import { MESSAGES_BOX } from '../../../components/communication/constants';
import { getCurrentLocationUUID } from '../../../utils/communicationUtils';
import { PLATFORM_LIST } from '../../../config/constants';


declare global {
  interface Window {
    lomavisUserData: any;
  }
}

const emptyNotificationsObject = {
  data: {
    conversations: {
      data: [],
      platforms: PLATFORM_LIST.reduce((acc, item) => {
        acc[item] = 0;
        return acc;
      }, {}),
      unread_count: 0
    },
    notifications: { data: [], unread_count: 0 }
  }
};
interface CommunicationState {
  data: {
    urls: any;
    locations: any;
    filters: any;
    conversationList: any;
    conversationDetails: any;
    updatedConversationsDetails: any;
    activeConversation: any;
    selectedMessage: any;
    pandingMessages: any;
    locationsUsers: any;
    labelsList: any;
    notifications: any;
  };
  ui: {
    rightMenuOpen: string | null;
    leftMenuOpen: string | null;
    conversationsFilters: boolean;
    generalInfo: boolean;
    helpMenuOpen: boolean;
  };
  components: {
    messagesBox: any;
    messageBoxWidth: any;
    quillEditor: any;
  };
  error: boolean;
  isLoading: boolean;
  isLocked: any[];
  subscription: any;
}
const initialState: CommunicationState = {
  subscription: {
    conversationDetails: () => {},
    conversationList: () => {},
    activeConversation: () => {},
    notifications: () => {}
  },
  ui: {
    rightMenuOpen: null,
    leftMenuOpen: MESSAGES_BOX,
    conversationsFilters: false,
    generalInfo: false,
    helpMenuOpen: false
  },

  data: {
    locationsUsers: {},
    labelsList: [],
    filters: {
      platforms: [],
      priority: [],
      status: [],
      labels: [],
      assigned_user_uuid: null,
      is_spam: null,
      is_marked: null,
      read: null,
      type: null,
      unassigned: null
    },
    notifications: {},

    // getCurrentLocationUUID()
    // ? {}
    // : JSON.parse(
    //     document.querySelector('#accountnotificationsummary')?.textContent ||
    //      '{}'
    //  ),
    urls: {},
    selectedMessage: { deleteMessageState: false, data: {} },
    locations: [],
    conversationList: {
      isLoading: false,
      data: [],
      lastVisited: null,
      lastRequested: null,
      nextPageIsLoading: false,
      nextPage: true,
      nextPageWithFilters: null,
      getNextPageFunction: () => {}
    },
    conversationDetails: {
      isLoading: false,
      data: {}
    },
    updatedConversationsDetails: {},
    activeConversation: null,
    pandingMessages: {}
  },
  components: {
    messagesBox: null,
    messageBoxWidth: null,
    quillEditor: null
  },
  error: false,
  isLoading: false,
  isLocked: []
};
const CONVERSATION_LIST_PAGE_LENGTH = 20;
const slice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setLocation: (state, action: PayloadAction<any>) => {
      if (
        state.data.locations
          .map((location: any) => location.uuid)
          .includes(action.payload.uuid)
      ) {
        state.data.locations = state.data.locations.filter(
          (location: any) => location.uuid !== action.payload.uuid
        );
      } else {
        state.data.locations.push(action.payload);
      }
    },
    resetLocations: (state) => {
      state.data.locations = [];
    },
    setConversationList: (state, action: PayloadAction<any>) => {
      state.data.conversationList.data = action.payload;
    },
    setConversationDetails: (state, action: PayloadAction<any>) => {
      state.data.conversationDetails.data = action.payload;
    },
    setUpdatedConversationsDetails(state, action: PayloadAction<any>) {
      const { data, lastRequested } = action.payload;
      const messages = state.data.conversationDetails.data.data;
      const newMessages = [];
      // if message uuid is in the new messages, replace it
      messages.forEach((message) => {
        const newMessage = data.find((item) => item.uuid === message.uuid);
        if (newMessage) {
          newMessages.push(newMessage);
        } else {
          newMessages.push(message);
        }
      });
      // push messages that are not in the old messages
      data.forEach((message) => {
        if (!messages.find((item) => item.uuid === message.uuid)) {
          newMessages.push(message);
        }
      });
      // update the state
      state.data.conversationDetails.data.data = newMessages;
      state.data.conversationDetails.data.lastRequested = lastRequested;
    },
    setActiveConversation: (state, action: PayloadAction<any>) => {
      state.ui.rightMenuOpen = null;
      state.data.activeConversation = action.payload;
    },
    setMessagesBoxWidth: (state, action: PayloadAction<any>) => {
      state.components.messageBoxWidth = action.payload;
    },
    setMessagesBox: (state, action: PayloadAction<any>) => {
      state.components.messagesBox = action.payload;
    },
    setSelectedMessage: (state, action: PayloadAction<any>) => {
      state.data.selectedMessage = {
        data: action.payload,
        deleteMessageState: false
      };
    },
    setDeletionMessage: (state, action: PayloadAction<any>) => {
      state.data.selectedMessage.deleteMessageState = action.payload;
    },
    startConversationListLoading: (state) => {
      state.data.conversationList.isLoading = true;
    },
    stopConversationListLoading: (state) => {
      state.data.conversationList.isLoading = false;
    },
    startConversationDetailsLoading: (state) => {
      state.data.conversationDetails.isLoading = true;
    },
    stopConversationDetailsLoading: (state) => {
      state.data.conversationDetails.isLoading = false;
    },
    saveLastVisitedConversation: (state, action: PayloadAction<any>) => {
      state.data.conversationList.lastVisited = action.payload;
    },
    startNextPageLoading: (state) => {
      state.data.conversationList.nextPageIsLoading = true;
    },
    stopNextPageLoading: (state) => {
      state.data.conversationList.nextPageIsLoading = false;
    },
    setNextPage: (state, action: PayloadAction<any>) => {
      state.data.conversationList.nextPage = action.payload;
    },
    // ui
    setRightMenuOpen: (state, action: PayloadAction<string | null>) => {
      state.ui.rightMenuOpen = action.payload;
    },
    setHelpMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.ui.helpMenuOpen = action.payload;
    },
    setLeftMenuOpen: (state, action: PayloadAction<string | null>) => {
      state.ui.leftMenuOpen = action.payload;
    },
    setConversationAsSeen: (state, action: PayloadAction<any>) => {
      state.data.conversationList.data = state.data.conversationList.data.map(
        (item: any) => {
          if (item.uuid === action.payload) {
            item.read = true;
          }
          return item;
        }
      );
    },
    setConversationFilter: (state, action: PayloadAction<any>) => {
      if (
        state.data.filters[action.payload.key]?.includes(action.payload.value)
      ) {
        state.data.filters[action.payload.key] = state.data.filters[
          action.payload.key
        ].filter((item: any) => item !== action.payload.value);
      } else state.data.filters[action.payload.key].push(action.payload.value);
    },
    setConversationFilterValues: (state, action: PayloadAction<any>) => {
      state.data.filters = { ...state.data.filters, ...action.payload };
    },
    setConversationLabelsFilter: (state, action: PayloadAction<any>) => {
      state.data.filters.labels = action.payload;
    },
    setAssignedUserFilter: (state, action: PayloadAction<any>) => {
      state.data.filters.assigned_user_uuid = action.payload;
    },
    setPlatformfilter: (state, action: PayloadAction<any>) => {
      if (action.payload.value) {
        state.data.filters.platforms.push(action.payload.platform);
      } else {
        state.data.filters.platforms = state.data.filters.platforms.filter(
          (platform: any) => platform !== action.payload.platform
        );
      }
    },
    setNextPageWithFilters: (state, action: PayloadAction<any>) => {
      state.data.conversationList.nextPageWithFilters = action.payload;
    },
    addPandingMessage: (state, action: PayloadAction<any>) => {
      const uuid = action.payload.uuid;
      const message = action.payload.message;
      if (Object.keys(state.data.pandingMessages).includes(uuid)) {
        console.log('pushed');
        state.data.pandingMessages[uuid].push(message);
      } else {
        state.data.pandingMessages[uuid] = [message];
      }
    },
    removePandingMessage: (state, action: PayloadAction<any>) => {
      // show values in panding messages
      console.log(
        current(state.data.pandingMessages),
        'remove state.data.pandingMessages'
      );
      console.log(action.payload, 'action.payload');
      const conversationUuid = action.payload.conversationUuid;
      const messageUuid = action.payload.messageUuid;
      state.data.pandingMessages[conversationUuid] = state.data.pandingMessages[
        conversationUuid
      ].filter((item) => item.uuid !== messageUuid);
      console.log('reached!aa');
    },
    removePandingMessages: (state, action: PayloadAction<any>) => {
      console.log(action.payload, 'action.payload');
      state.data.pandingMessages[action.payload.uuid] =
        state?.data.pandingMessages?.[action.payload.uuid]?.filter(
          (item) => !action.payload?.recievedMessagesUUids?.includes(item.uuid)
        ) || [];
    },
    addLock: (state, action: PayloadAction<any>) => {
      state.data.updatedConversationsDetails = null;
      state.isLocked.push({ time: action.payload, status: true });
    },
    removeLock: (state, actions: PayloadAction<any>) => {
      state.isLocked = state.isLocked.filter(
        (item) => item.time !== actions.payload.time
      );
      if (
        state.isLocked.length === 0 &&
        state.data.updatedConversationsDetails
      ) {
        // update panding messages
        const recievedMessagesUUids = [];
        state.data.updatedConversationsDetails?.data?.map((item) => {
          item?.answers?.map((item: any) =>
            recievedMessagesUUids.push(item.uuid)
          );
          recievedMessagesUUids.push(item.uuid);
          return null;
        });
        console.log('recievedMessagesUUids3', recievedMessagesUUids);
        const conversationUuid = actions.payload.conversationUuid;
        console.log(
          current(state.data.pandingMessages[conversationUuid]),
          'state.data.pandingMessages[conversationUuid]'
        );

        state.data.pandingMessages[conversationUuid] =
          state.data.pandingMessages[conversationUuid].filter(
            (item) => !recievedMessagesUUids?.includes(item.uuid)
          );
        state.data.conversationDetails.data =
          state.data.updatedConversationsDetails;

        state.data.updatedConversationsDetails = null;
      }
      console.log('remove lock', actions.payload.time, state.isLocked);
      // remove panding messages
      // if (!evaluateLocks(state.isLocked)) {
      //  const updatedUUids = state.data?.updatedConversationsDetails?.data?.map(
      //    (item: any) => item.uuid
      //  );
      //
      //  state.data.pandingMessages[actions.payload?.conversationUuid] =
      //    state.data.pandingMessages[actions.payload?.conversationUuid].filter(
      //      (item) => !updatedUUids?.includes(item.uuid)
      //    );
      //  // sync conversation state
      //  console.log(
      //    'sync conversation state',
      //    state.data.updatedConversationsDetails
      //  );
      //  if (state.data.updatedConversationsDetails) {
      //    console.log('sync conversation state');
      //    state.data.conversationDetails.data =
      //      state.data.updatedConversationsDetails;
      //  }
      // }
    },
    updatePandingMessageUuid: (state, action: PayloadAction<any>) => {
      const conversationUuid = action.payload.conversationUuid;
      state.data.pandingMessages[conversationUuid] = state.data.pandingMessages[
        conversationUuid
      ].map((item) => {
        if (item.id === action.payload.id) {
          item.uuid = action.payload.uuid;
        }
        return item;
      });
    },
    addUrl(state, action: PayloadAction<any>) {
      state.data.urls[action.payload.key] = action.payload.value;
    },
    setConversationsFilters: (state, action: PayloadAction<any>) => {
      state.ui.conversationsFilters = action.payload;
    },
    resetAllFilter: (state) => {
      state.data.filters = {
        platforms: [],
        priority: [],
        status: [],
        assigned_user_uuid: null,
        is_spam: null,
        is_marked: null,
        read: null,
        labels: [],
        type: null,
        unassigned: null
      };
    },
    setLocationUsers: (state, action: PayloadAction<any>) => {
      state.data.locationsUsers[action.payload.locationUuid] =
        action.payload.users;
    },
    setLabelsList: (state, action: PayloadAction<any>) => {
      state.data.labelsList = action.payload;
    },
    setNextPageFunction: (state, action: PayloadAction<any>) => {
      state.data.conversationList.getNextPageFunction = action.payload;
    },
    setGeneralInfo: (state, action: PayloadAction<any>) => {
      state.ui.generalInfo = action.payload;
    },
    updateConversationAttributes: (state, action: PayloadAction<any>) => {
      state.data.conversationList.data = state.data.conversationList.data.map(
        (item: any) => {
          if (item.uuid === action.payload.uuid) {
            item = { ...item, ...action.payload.attributes };
          }
          return item;
        }
      );
    },
    setNotifications: (state, action: PayloadAction<any>) => {
      state.data.notifications = action.payload;
    },
    setQuillEditor: (state, action: PayloadAction<any>) => {
      state.components.quillEditor = action.payload;
    },
    removeConversationFromList: (state, action: PayloadAction<any>) => {
      state.data.conversationList.data =
        state.data.conversationList.data.filter(
          (item: any) => item.uuid !== action.payload
        );
    },
    setConversationListLastRequested(state, action: PayloadAction<any>) {
      state.data.conversationList.lastRequested = action.payload;
    }

    // rest of reducers here
  }
});

export default slice.reducer;
export function setGeneralInfo(state: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setGeneralInfo(state));
  };
}

export function resetAllFilter() {
  return async (dispatch: any) => {
    dispatch(slice.actions.resetAllFilter());
  };
}
export function updateConversationAttributesLocaly(uuid: any, attributes: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.updateConversationAttributes({ uuid, attributes }));
  };
}
export const setConversationListNextpageFunction =
  (func: any) => async (dispatch: any) => {
    dispatch(slice.actions.setNextPageFunction(func));
  };
export const setAssignedUserFilter = (uuid: any) => async (dispatch: any) => {
  dispatch(slice.actions.setAssignedUserFilter(uuid));
};
export const getLabelsList = () => async (dispatch: any) => {
  let url = resolve('labels_list', {}, {});
  const data = [];
  while (url) {
    const res = await axiosClient.get(url);
    data.push(...(res?.data?.results || []));
    url = res.data?.next;
  }
  dispatch(slice.actions.setLabelsList(data));
};
export function setLocationUsers(locationUuid: string) {
  return async (dispatch: any) => {
    const url = resolve('members_list', { location: 'dieter-company' }, {});
    const res = await axiosClient.get(url);

    dispatch(
      slice.actions.setLocationUsers({ locationUuid, users: res.data?.results })
    );
  };
}
export function setConversationFilter(key: string, value: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setConversationFilter({ key, value }));
  };
}
export function setConversationFilterValues(obj) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setConversationFilterValues(obj));
  };
}
export const setConversationLabelsFilter =
  (labels: any) => async (dispatch: any) => {
    dispatch(slice.actions.setConversationLabelsFilter(labels));
  };

export function setPlatformfilter(platform: string, value: boolean) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setPlatformfilter({ platform, value }));
  };
}

export function setRightMenuOpen(rightMenuOpen: string | null) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setRightMenuOpen(rightMenuOpen));
  };
}
export function setHelpMenuOpen(helpMenuOpen: boolean) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setHelpMenuOpen(helpMenuOpen));
  };
}
export function setLeftMenuOpen(leftMenuOpen: string | null) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setLeftMenuOpen(leftMenuOpen));
  };
}

export function setLocation(state: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.setLocation(state));
  };
}

export function setActiveConversation(state: any) {
  return async (dispatch: any, getState: any) => {
    if (!state) {
      dispatch(slice.actions.setActiveConversation(null));
      // getState().communication.subscription.activeConversation();
      return;
    }

    dispatch(slice.actions.setActiveConversation(state));
    // getState().communication.subscription.activeConversation();
  };
}

export const setConversationsFilters =
  (state: any) => async (dispatch: any) => {
    dispatch(slice.actions.setConversationsFilters(state));
  };

const buildFilters = (communication: any): any => {
  const filters = {} as any;
  if (communication.data.locations.length > 0) {
    filters.location_uuid = communication.data.locations.map(
      (location: any) => location.uuid
    );
  }
  if (communication.data.filters.platforms.length > 0) {
    filters.platform = communication.data.filters.platforms;
  }
  if (communication.data.filters.priority.length > 0) {
    filters.priority = communication.data.filters.priority;
  }
  if (communication.data.filters?.labels?.length > 0) {
    filters.conversation_labels = communication.data.filters.labels;
  }
  if (communication.data.filters.status.length > 0) {
    filters.status = communication.data.filters.status;
  }
  if (communication.data.filters.assigned_user_uuid) {
    filters.assigned_to = communication.data.filters.assigned_user_uuid;
  }
  if (communication.data.filters.unassigned !== null) {
    filters.unassigned = communication.data.filters.unassigned;
  }
  if (communication.data.filters.is_spam !== null) {
    filters.is_spam = communication.data.filters.is_spam;
  }
  if (communication.data.filters.is_marked !== null) {
    filters.is_marked = communication.data.filters.is_marked;
  }
  if (communication.data.filters.read !== null) {
    filters.read = communication.data.filters.read;
  }
  if (communication.data.filters.type) {
    filters.type = communication.data.filters.type;
  }
  return filters;
};
export const getConversationListWithFilters =
  () => async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startConversationListLoading());
    // unsubscripe from previous list
    const communication = getState().communication;
    // locations filter
    const filters = buildFilters(communication);
    const url = resolve('conversations_filter', {}, filters);
    const res = await axiosClient.get(url);
    // setNextPageWithFilters
    dispatch(slice.actions.setNextPageWithFilters(res.data?.next));
    dispatch(slice.actions.setNextPage(!!res.data?.next));
    // set result.data for all conversations
    const results = [];
    res.data?.results.forEach((item: any) => {
      results.push(item.data);
    });
    dispatch(slice.actions.setConversationList(results));
    dispatch(
      slice.actions.setNextPageFunction(
        getConversationListForNextPageWithFilters
      )
    );
    dispatch(slice.actions.stopConversationListLoading());
  };
export const getConversationListForNextPageWithFilters =
  () => async (dispatch: any, getState: any) => {
    if (!getState().communication.data.conversationList.nextPageWithFilters) {
      dispatch(slice.actions.setNextPage(false));
      console.log('no more pages');
      return;
    }
    dispatch(slice.actions.startNextPageLoading());
    const communication = getState().communication;
    const url = communication.data.conversationList.nextPageWithFilters;
    if (!url) {
      dispatch(slice.actions.setNextPage(false));
      console.log('no more pages');
      return;
    }
    const res = await axiosClient.get(url);
    // setNextPageWithFilters
    // set result.data for all conversations
    const results = [...communication.data.conversationList.data];
    res.data?.results.forEach((item: any) => {
      results.push(item.data);
    });
    dispatch(slice.actions.setConversationList(results));
    dispatch(slice.actions.setNextPageWithFilters(res.data?.next));
    dispatch(slice.actions.stopNextPageLoading());
  };

export const getConversationListForNextPage =
  (callback: any) => async (dispatch: any, getState: any) => {
    if (!getState().communication.data.conversationList.lastVisited) {
      dispatch(slice.actions.setNextPage(false));
      console.log('no more pages');
      return;
    }
    dispatch(getConversationListForNextPageWithFilters());
  };

export const getConversationDetailsFromBackend =
  (uuid: string) => async (dispatch: any) => {
    dispatch(slice.actions.startConversationDetailsLoading());
    const url = resolve(
      'communication_messages',
      {},
      { conversation__uuid: uuid }
    );
    const lastRequested = new Date().toISOString();
    const res = await axiosClient.get(url);

    if (res.data.results.length > 0) {
      // order new data by platform_created
      res.data.results = res.data.results.sort((a, b) => {
        // @ts-ignore
        return new Date(a.platform_created) - new Date(b.platform_created);
      });
    }

    dispatch(
      slice.actions.setConversationDetails({
        data: res.data.results,
        nextPage: res.data.next,
        length: res.data.count,
        lastRequested
      })
    );
    dispatch(slice.actions.stopConversationDetailsLoading());
  };
export const getCoversationDetails =
  (uuid: string) => async (dispatch: any, getState: any) => {
    dispatch(getConversationDetailsFromBackend(uuid));
  };

export const getLatestConversationsDetailsUpdates =
  (uuid: string) => async (dispatch: any, getState: any) => {
    const lastRequested =
      getState().communication.data.conversationDetails.data.lastRequested;
    const now = new Date().toISOString();
    const url = resolve(
      'communication_messages',
      {},
      { conversation__uuid: uuid, modified__gte: lastRequested }
    );
    const res = await axiosClient.get(url);
    dispatch(
      slice.actions.setUpdatedConversationsDetails({
        data: res.data.results,
        lastRequested: now
      })
    );
  };
export const getConversationDetailsForNextPage =
  (callback: any) => async (dispatch: any, getState: any) => {

    if (!getState().communication.data.conversationDetails.data.nextPage) {

      return;
    }
    const url = getState().communication.data.conversationDetails.data.nextPage;
    const res = await axiosClient.get(url);

    let newData = [
      ...res.data.results,
      ...getState().communication.data.conversationDetails.data.data
    ]

    // filter out double entries by uuid
    newData = newData.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.uuid === item.uuid
      )))

    // order new data by platform_created
    newData = newData.sort((a, b) => {
      // @ts-ignore
      return new Date(a.platform_created) - new Date(b.platform_created);
    });

    // add new data to the existing data
    dispatch(
      slice.actions.setConversationDetails({
        // data: [
        //  ...res.data.results,
        //  ...getState().communication.data.conversationDetails.data.data
        //],
        data: newData,
        nextPage: res.data.next,
        length: res.data.count
      })
    );
  };
export const setMessagesBox = (messagesBox: any) => async (dispatch: any) => {
  dispatch(slice.actions.setMessagesBox(messagesBox));
};
export const setMessagesBoxWidth =
  (messageBoxWidth: any) => async (dispatch: any) => {
    dispatch(slice.actions.setMessagesBoxWidth(messageBoxWidth));
  };

export const getActiveConversation =
  (uuid: string) => async (dispatch: any, getState: any) => {
    const url = resolve('communication_conversation', { uuid }, {});
    const res = await axiosClient.get(url);
    dispatch(setActiveConversation(res.data));
  };

export const setConversationAsSeen =
  (uuid: string) => async (dispatch: any) => {
    dispatch(slice.actions.setConversationAsSeen(uuid));
    const url = resolve('communication_conversation', { uuid }, {});
    try {
      await axiosClient.patch(url, { read: true });
    } catch (err: any) {
      console.log(err);
    }
  };
export const setConversationRating =
  (uuid: string, rating) => async (dispatch: any) => {
    const url = resolve('communication_conversation', { uuid }, {});
    try {
      await axiosClient.patch(url, { internal_rating: rating });
    } catch (err: any) {
      console.log(err);
    }
  };

export const setConversationAttributes =
  (uuid: string, attributes, callback = () => {}) =>
  async (dispatch: any) => {
    const url = resolve('communication_conversation', { uuid }, {});
    try {
      await axiosClient.patch(url, attributes);
      callback();
    } catch (err: any) {
      console.log(err);
    }
  };

export const sendAmessage =
  (
    message: string,
    conversationUuid: string,
    messageUuid: string = null,
    isInternaNote: boolean = false,
    successCallback: any
  ) =>
  async (dispatch: any, getState: any) => {
    const url = resolve('communication_messages', {}, {});
    try {
      const res = (await axiosClient.post(url, {
        parent_message_uuid: messageUuid,
        conversation_uuid: conversationUuid,
        is_internal_note: isInternaNote,
        message_content_quill: message
      })) as any;
      // get json response
      console.log('received response from backend');
      successCallback(res?.data?.uuid);
    } catch (err: any) {
      console.log(err);
    }
  };

export const updateMessage =
  (
    message: string,
    messageUuid: string,
    conversationUuid: string,
    successCallback: any
  ) =>
  async (dispatch: any) => {
    const url = resolve('update_message', { uuid: messageUuid }, {});
    try {
      await axiosClient.patch(url, {
        message_content_quill: message,
        conversation_uuid: conversationUuid,
        uuid: messageUuid
      });
      successCallback();
    } catch (err: any) {
      console.log(err);
    }
  };
export const likeAMessage =
  (messageUuid: string, conversationUuid: string, successCallback: any) =>
  async (dispatch: any) => {
    const url = resolve('update_message', { uuid: messageUuid }, {});
    try {
      await axiosClient.patch(url, {
        is_liked_by_me: true,
        conversation_uuid: conversationUuid,
        uuid: messageUuid
      });
      successCallback();
    } catch (err: any) {
      console.log(err);
    }
  };

export const deleteMessage =
  (messageUuid: string, successCallback: any) => async (dispatch: any) => {
    const url = resolve('update_message', { uuid: messageUuid }, {});
    try {
      await axiosClient.delete(url);
      successCallback();
    } catch (err: any) {
      console.log(err);
    }
  };
export const setSelectedMessage = (message: any) => async (dispatch: any) => {
  dispatch(slice.actions.setSelectedMessage(message));
};
export const setMessageDeletionState =
  (state: boolean) => async (dispatch: any) => {
    dispatch(slice.actions.setDeletionMessage(state));
  };

export const addPandingMessage = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.addPandingMessage(data));
};
export const removePandingMessage = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.removePandingMessage(data));
};

export const updatePandingMessageUuid =
  (conversationUuid: string, id: string, uuid: string) =>
  async (dispatch: any) => {
    dispatch(
      slice.actions.updatePandingMessageUuid({ conversationUuid, id, uuid })
    );
  };
export const addLock = (time: any) => async (dispatch: any) => {
  dispatch(slice.actions.addLock(time));
};
export const removeLock =
  (conversationUuid, time: any, uuid) => async (dispatch: any) => {
    dispatch(slice.actions.removeLock({ conversationUuid, time, uuid }));
  };
export const addUrl = (key: string, value: string) => async (dispatch: any) => {
  dispatch(slice.actions.addUrl({ key, value }));
};
export const runConversationBulkOperation =
  (params: any, successCallback: any = () => {}, stopLoading = false) =>
  async (dispatch: any) => {
    dispatch(slice.actions.startConversationListLoading());
    const url = resolve('conversations_bulk_operation', {}, {});
    try {
      await axiosClient.post(url, params);
      successCallback();
      if (stopLoading) dispatch(slice.actions.stopConversationListLoading());
    } catch (err: any) {
      console.log(err);
    }
  };
export const getConversationsList =
  () => async (dispatch: any, getState: any) => {
    // set lastRequested
    const lastRequested = new Date().toISOString();
    dispatch(slice.actions.setConversationListLastRequested(lastRequested));
    dispatch(getConversationListWithFilters());
  };

export const getConversationListUpdates =
  () => async (dispatch: any, getState: any) => {
    const communication = getState().communication;
    const lastRequested = communication.data.conversationList.lastRequested;
    const newLastRequested = new Date().toISOString();
    dispatch(slice.actions.setConversationListLastRequested(newLastRequested));
    const filters = buildFilters(communication);
    const url = resolve(
      'conversations_filter',
      {},
      { modified__gte: lastRequested, ...filters }
    );

    // Fetch initial data
    const results: any[] = [];
    const res = await axiosClient.get(url);
    const resultsData = res.data.results.map((item: any) => item.data);
    results.push(...resultsData);

    // Fetch additional pages if available
    let next = res.data?.next;
    while (next) {
      const pageRes = await axiosClient.get(next);
      const pageResultsData = pageRes.data.results.map(
        (item: any) => item.data
      );
      results.push(...pageResultsData);
      next = pageRes.data?.next;
    }

    const oldResults = communication.data.conversationList.data;

    // Update any existing items that match newly fetched items by uuid
    const updatedResults = oldResults.map((oldItem: any) => {
      const matchingNew = results.find(
        (newItem: any) => newItem.uuid === oldItem.uuid
      );
      return matchingNew || oldItem;
    });

    // Determine which newly fetched items are not already in updatedResults
    const newUniqueResults = results.filter(
      (newItem: any) =>
        !updatedResults.some((existing: any) => existing.uuid === newItem.uuid)
    );

    // Prepend the new unique results so they appear at the beginning
    const finalResults = [...newUniqueResults, ...updatedResults];

    dispatch(slice.actions.setConversationList(finalResults));
  };

export const deleteLabel = (uuid: string, successCallback: any) => async () => {
  const url = resolve('update_label', { uuid }, {});
  try {
    await axiosClient.delete(url);
    successCallback();
  } catch (err: any) {
    console.log(err);
  }
};

export const removeLabelfromLabelsList =
  (uuid: string) => async (dispatch: any, getState: any) => {
    const labels = getState().communication.data.labelsList;
    const newLabels = labels.filter((label: any) => label.uuid !== uuid);
    dispatch(slice.actions.setLabelsList(newLabels));
  };

export const resetLocations = () => async (dispatch: any) => {
  dispatch(slice.actions.resetLocations());
};

export const setQuillEditortoSlice =
  (quillEditor: any) => async (dispatch: any) => {
    dispatch(slice.actions.setQuillEditor(quillEditor));
  };
export const createNewLabel =
  (label: any, successCallback: any) => async () => {
    const url = resolve('labels_list', {}, {});
    try {
      const res = await axiosClient.post(url, label);
      successCallback(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

export const removeConversationFromList =
  (uuid: string) => async (dispatch: any) => {
    dispatch(slice.actions.removeConversationFromList(uuid));
  };

export const getNotifications = () => async (dispatch: any, getState: any) => {
  const locationUuid = getCurrentLocationUUID();
  const query: any = {};
  if (locationUuid) query.location__uuid = locationUuid;

  let url = resolve('notifications', {}, query);
  const allResults: any[] = [];

  // Fetch all pages
  while (url) {
    const res = await axiosClient.get(url);
    const { results, next } = res.data;
    allResults.push(...results);
    url = next;
  }

  if (!allResults.length) return;

  // Prepare an aggregator for all data
  const aggregatedData = {
    conversations: {
      data: [],
      platforms: PLATFORM_LIST.reduce((acc, key) => {
        acc[key] = 0; // or any initial value
        return acc;
      }, {}),
      unread_count: 0
    },
    notifications: {
      data: [],
      unread_count: 0
    }
  };

  // Aggregate over all results
  allResults.forEach((item: any) => {
    const { data } = item.data;

    aggregatedData.conversations.data.push(...(data.conversations.data || []));

    aggregatedData.conversations.unread_count +=
      data.conversations.unread_count;

    Object.keys(data.conversations.platforms).forEach((platformKey) => {
      aggregatedData.conversations.platforms[platformKey] +=
        data.conversations.platforms[platformKey];
    });

    aggregatedData.notifications.data.push(...(data.notifications.data || []));

    aggregatedData.notifications.unread_count +=
      data.notifications.unread_count;
  });

  // Finally, dispatch the aggregated data
  dispatch(slice.actions.setNotifications({ data: aggregatedData }));
};

export const resetNotifications = () => async (dispatch: any) => {
  dispatch(slice.actions.setNotifications(emptyNotificationsObject));
};

export const updateNotificationsCount =
  (platform: string, value: number) => async (dispatch: any, getState: any) => {
    const notifications = getState().communication.data.notifications;

    if (
      !notifications ||
      !notifications.data ||
      !notifications.data.conversations
    ) {
      console.error('Invalid notifications structure');
      return;
    }

    const { platforms, unread_count } = notifications.data.conversations;
    if (!platforms || typeof platforms !== 'object') {
      console.error('Invalid platforms structure');
      return;
    }

    const newNotifications = {
      ...notifications,
      data: {
        ...notifications.data,
        conversations: {
          ...notifications.data.conversations,
          platforms: {
            ...platforms,
            [platform]: (platforms[platform] || 0) + value
          },
          unread_count: unread_count + value
        }
      }
    };

    dispatch(slice.actions.setNotifications(newNotifications));
  };
